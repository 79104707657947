import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Col } from '@catalogo/theme-grid';
import styled from 'styled-components';

const getKey = obj => {
  let output = '';
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      output += obj[key];
    }
  }
  return output;
};
class Component extends React.Component {
  getColumnNumberProp(numberOfColumns) {
    return parseInt(12 / numberOfColumns);
  }

  hasMarginGrid() {
    const { options = '' } = this.props.publication;
    return options.indexOf('Espaçamento Externo') > -1;
  }

  hasPaddingCol() {
    const { options = '' } = this.props.publication;
    return options.indexOf('Sem Espaçamento Vertical Interno') === -1;
  }

  hasGap() {
    const { options = '' } = this.props.publication;
    return options.indexOf('Espaçamento Interno') > -1;
  }

  render() {
    const { renderComponent, publication } = this.props;
    const { sm, md, xs } = publication;
    const hasMarginGrid = this.hasMarginGrid();
    const hasPaddingCol = this.hasPaddingCol();
    const hasGap = this.hasGap();

    return (
      <StyledGrid hasMarginGrid={hasMarginGrid}>
        {this.props.publication.children.map(child => (
          <StyledCol
            hasPaddingCol={hasPaddingCol}
            gap={hasGap ? '10px' : '0px'}
            key={getKey(child)}
            xs={this.getColumnNumberProp(xs)}
            sm={this.getColumnNumberProp(sm)}
            md={this.getColumnNumberProp(md)}
          >
            {renderComponent(child)}
          </StyledCol>
        ))}
      </StyledGrid>
    );
  }
}

const StyledGrid = styled(Grid)`
  margin: ${({ hasMarginGrid }) => (hasMarginGrid ? '6px' : 0)};
`;

const StyledCol = styled(Col)`
  padding: ${({ hasPaddingCol }) => (hasPaddingCol ? '0 0 12px 0' : 0)};
  width: 100%;
`;

Component.propTypes = {
  renderComponent: PropTypes.func,
  publication: PropTypes.object,
};
export default Component;
